import React from 'react';
import Helmet from 'react-helmet';
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Navigation from '../components/Navigation';
import usagicon from "../images/usagi.ico"

import style from "../styles/global.module.css";

export default ({ data }) => {
  let front = data.mdx.frontmatter
  let body = data.mdx.body
  return (
    <div className={style.wrap}>
      <Helmet>
          <title>norweijian.</title>
          <link rel="icon" href={usagicon} />
      </Helmet>
      <Navigation />
      <main className={style.maincontainer}>
        <div className={style.postcontainer}>
          <Img
            fluid={front.featuredImage.childImageSharp.fluid}
            style={{margin:'1rem', maxHeight:'calc(50vh - 4rem'}}
            imgStyle={{objectFit:'contain'}}
          />
          <h1>{front.title}</h1>
          <span>{front.date}</span>
          <div className={style.postbody}>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>
      </main>
    </div>
  );
};

export const query = graphql`
  query Post($slug: String!) {
    mdx(slug: {eq: $slug}) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`