import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import nav from "./navigation.module.css"

export default () => (
  <StaticQuery
    query = {graphql`
      query NavigationQuery {
        allMdx(
          limit: 3
          sort: {fields: [frontmatter___date], order: DESC}
          filter: {frontmatter: {published: {eq: true}}}
        ) {
          nodes {
            slug
            frontmatter {
              title
            }
          }
        }
      }
    `}
      render = {data => (
        <div className={nav.container}>
          <header className={nav.header}>
            <Link to="/"><span className={nav.logo}>norweijian</span></Link>
            <Link to="/gatsby"><span className={nav.description} style={{"display":"block"}}>by Gatsby</span></Link>
          </header>
          <nav className={nav.bar}>
            {/* <Link className="a-hover-blink" to="/norway"><span className={nav.single}>norway</span></Link> */}
            <Link className="a-hover-blink" to="/posts"><span className={nav.single}>all posts</span></Link>
            <span className={nav.single}>recent posts</span>
            {/* three most recent posts. */}
            <ul>
              {data.allMdx.nodes.map(({frontmatter, slug}) => (
                <Link className="a-hover-link" to={`/${slug}`}>
                  <li className={nav.list}>{frontmatter.title}</li>
                </Link>
              ))}
            </ul>
          </nav>
        </div>
      )}
  />
)